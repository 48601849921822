import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { createSignal, Show } from 'solid-js';
import { getOrdersServer } from '~/services/server';
import { Order } from '~/services/shopify/getOrders';

export type TabId = 'overview' | 'details';

export const OrdersPage = () => {
  const [orders, setOrders] = createSignal<{ cursor: string; node: Order }[]>(
    [],
  );
  const [order, setOrder] = createSignal<Order>();
  const [activeTab, setActiveTab] = createSignal<TabId>('overview');

  const fetchOrders = async () => {
    try {
      const resp = await getOrdersServer();
      if (!resp.result.value) return;
      resp.result.value.orders.edges.map((order) => console.log(order));
      setOrders(resp.result.value.orders.edges);
    } catch {}
  };
  fetchOrders();

  function selectOrder(id: string) {
    const orderFound = orders().find((order) => order.cursor === id);
    if (!orderFound) {
      console.error('Order not found');
      return;
    }
    const order: Order = orderFound.node;
    setOrder(order);
  }
  return (
    <div class="container mx-auto p-6">
      <h1 class="text-3xl font-bold mb-8">Orders DB</h1>
      <div class="grid grid-cols-4 gap-6">
        <div class="col-span-1">
          <Show when={orders}>
            {orders().map((order) => (
              <div class="flex items-center justify-between p-4 bg-white mb-1 rounded-sm">
                <div>
                  <h3 class="text-lg font-medium">
                    {order.node.shippingAddress?.name}
                  </h3>
                  <p class="text-sm text-muted-foreground">
                    {order.node.totalPriceSet.shopMoney.amount}
                  </p>
                </div>
                <div
                  class="cursor-pointer inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary text-primary-foreground hover:bg-primary/80 rounded-md"
                  onClick={() => selectOrder(order.cursor)}
                >
                  Click to view
                </div>
              </div>
            ))}
          </Show>
        </div>

        <div class="col-span-3">
          <Show
            when={orders()}
            fallback={
              <div class="flex items-center justify-center h-full">
                <p class="text-muted-foreground">
                  Select a order from the list to view details
                </p>
              </div>
            }
          >
            <div class="flex justify-between items-center mb-6">
              <h2 class="text-2xl font-bold">{order() && order()?.name}</h2>
            </div>
            {/* <Tabs
              value={activeTab()}
              onChange={(value) => setActiveTab(value as TabId)}
            >
              <TabsList>
                <TabsTrigger value="overview">Overview</TabsTrigger>
              </TabsList>

              <TabsContent value="overview">
                <div class="relative w-full overflow-auto">
                  <table class="w-full caption-bottom text-sm">
                    <thead class="[&_tr]:border-b">
                      <tr class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          Date
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          Customer
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          Total
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          Fulfillment Status
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          Items
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          Delivery Method
                        </td>
                      </tr>
                    </thead>
                    <tbody class="[&_tr:last-child]:border-0">
                      <tr class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          {order().node.processedAt}
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          {order().node.shippingAddress.name}
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          {order().node.totalPriceSet.shopMoney.amount}
                          {order().node.totalPriceSet.shopMoney.currencyCode}
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          {order().node.fulfillments[0].status === 'SUCCESS'
                            ? 'Fulfilled'
                            : 'Unfulfilled'}
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          {order().node.lineItems.edges.length} items
                        </td>
                        <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                          {order().node.shippingLine.title}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabsContent>
            </Tabs> */}
          </Show>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
