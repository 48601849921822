import { Component, JSX, Show } from 'solid-js';
import { createSignal, onMount } from 'solid-js';
import OrdersPage from '~/pages/OrdersPage';

interface ClientOnlyProps {
  children: JSX.Element;
  fallback?: JSX.Element;
}

const ClientOnly: Component<ClientOnlyProps> = (props) => {
  const [isClient, setIsClient] = createSignal(false);

  onMount(() => {
    setIsClient(true);
  });

  return (
    <Show when={isClient()} fallback={props.fallback || null}>
      {props.children}
    </Show>
  );
};

export default function Page() {
  return (
    <div>
      <ClientOnly fallback={<p>Loading Client Control...</p>}>
        <OrdersPage />
      </ClientOnly>
    </div>
  );
}
